import React from 'react';
import styled from 'styled-components'

const Pill = styled.span`
    padding: .25rem .75rem;
    background: #111;
    color: #fff;
    margin: .5rem .5rem;
    margin-left: 0;
    border-radius: 3px;
`

const PillsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;
`

const EventPills = ({tags}) => {
    return (
      <PillsContainer>
        {tags.map(tag => <Pill>{tag}</Pill>)}
      </PillsContainer>
    )
}

export default EventPills;
