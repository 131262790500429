import React from "react"
import moment from "moment"

import styled from "styled-components"
import EventDate from "./EventDate"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGlassCheers,
  faCalendarWeek,
  faClock,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons"

const addHTTP = url => {
  var prefix = "https://"
  if (url.substr(0, prefix.length) !== prefix) {
    return prefix + url
  }
  return url
}

const CTAButton = styled.a`
  color: rgb(0, 86, 125);

  display: flex;
  border: 1px solid rgb(0, 86, 125);
  padding: 1rem 2rem;
  font-size: 0.9em;
  font-weight: 400;
  width: fit-content;
  line-height: 1.5;
  cursor: pointer;
  margin: 1rem 1rem 1rem 0rem;
  &:hover {
    text-decoration: underline;
    color: black;
  }
`
export default function EventsModalBasic({ event, modalShowing, closeModal }) {
  const { name, starts, ends, description, imageurl, image, ctas } = event

  const renderCTAs = ctas => {
    const buttonTitles = Object.keys(ctas).filter(
      btn => btn !== "cta1" && btn !== "cta2"
    )
    return buttonTitles.map(button => (
      <CTAButton
        target="_blank"
        rel="noopener noreferrer"
        href={addHTTP(ctas[button])}
      >
        {button}
      </CTAButton>
    ))
  }

  return modalShowing ? (
    <div className="modalcontainer">
      <div className="modalbackground" onClick={() => closeModal()} />
      <div className="modal-basic">
        <img className="modalimage" src={imageurl || image} alt={name} />

        <div className="modalcontentcontainer">
          <p className="modaleventname">
            <FontAwesomeIcon
              style={{
                marginRight: "7px",
                marginTop: "5px",
              }}
              icon={faGlassCheers}
            />{" "}
            {name}
          </p>

          <div className="modaleventdate">
            <FontAwesomeIcon
              style={{
                marginRight: "10px",
                marginLeft: "2px",
                fontSize: "22px",
              }}
              icon={faCalendarWeek}
            />

            <EventDate date={starts} />
            {/* <EventDate date={ends} /> */}
          </div>

          <p className="modaleventtime">
            <FontAwesomeIcon
              style={{ marginRight: "10px", fontSize: "22px" }}
              icon={faClock}
            />
            {`${moment(starts).format("hh:mm")}`}
            {/* {`${moment(ends).format("hh:mm")}`} */}
          </p>

          <div className="descriptioncontainer">
            <FontAwesomeIcon
              style={{
                marginRight: "14px",
                fontSize: "22px",
                marginLeft: "2px",
              }}
              icon={faClipboard}
            />
            <p className="">{description}</p>
          </div>
          <div style={{ mt: "2rem" }}>{renderCTAs(ctas)}</div>
        </div>
      </div>
    </div>
  ) : (
    ""
  )
}
