import React from "react"
import { Box, Text } from "theme-ui"
import moment from "moment"
export default function EventDate({ date }) {
  console.log("date we recieved: ", date)
  if (!date) {
    return null
  }
  return (
    <div className="eventitemdatecontainer">
      <p className="eventdate">{`${moment(date).format("DD")} ${moment(
        date
      ).format("MMM")}`}</p>
    </div>
  )
}
