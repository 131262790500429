import React, { useState } from "react"
import Layout from "../components/Layout"
import DiscoverEvents from "../components/DiscoverEvents/DiscoverEvents"
export default function Specials({ data }) {
  return (
    <Layout pageTitle="events">
      <DiscoverEvents
        id={data.siteMetaData.bizID}
        bizName={data.siteMetaData.name}
      />
    </Layout>
  )
}

export const query = graphql`
  {
    siteMetaData {
      avatar {
        imageBaseUrl
      }
      city
      desc
      cover {
        imageBaseUrl
      }
      hours {
        fri {
          close
          open
        }
        mon {
          close
          open
        }
        sat {
          close
          open
        }
        sun {
          close
          open
        }
        thu {
          close
          open
        }
        tue {
          close
          open
        }
        wed {
          close
          open
        }
      }
      lastPricelistUpdate {
        sec
        usec
      }
      links {
        facebook
        instagram
        twitter
        youtube
        website
      }
      loc
      slug
      name
      phone
      state
      street
      zip
      bizID
    }
  }
`
