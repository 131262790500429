import React, { useState, useEffect } from "react"
// import { Link } from "gatsby"
import axios from "axios"
import { ImEnlarge } from "react-icons/im"
import "../../styles/seasons.scss"
import cloudinaryOptimize from "../../helpers/cloudinaryOptimize"
import moment from "moment"
import styled from "styled-components"
import EventPills from "./EventPills"
import capitalize from "../../helpers/capitalize"
import Lightbox from "react-image-lightbox"
import EventsModalBasic from "../events/EventsModalBasic"
let jsonpAdapter = require("axios-jsonp")

const DiscoverEventsContainer = styled.div`
  color: #fff;
  .modalcontainer {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-basic {
	  max-width: 800px;
    background: white;
}
  }
`

const DiscoverEventsColumns = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const EventContent = styled.div`
  width: 100%;
  padding: 2rem 1.5rem;
  background: rgb(0, 86, 125);
  background: linear-gradient(
    90deg,
    rgba(0, 86, 125, 1) 50%,
    rgba(0, 151, 255, 1) 100%
  );
  color: #fff;
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 50%;
    ${({ flip }) => (flip ? "order: 99" : "")};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`

const EventImage = styled.div`
  cursor: pointer;
  width: 100%;
  order: -1;
  position: relative;
  img {
    display: block;
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      max-height: 500px;
      object-fit: cover;
      width: 100%;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex: 1;
    &:nth-of-type(even) {
      order: 4;
    }
  }
`

const EventName = styled.h3`
  font-size: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font-weight: 600;
`

const LocationText = styled.h5`
  font-size: 1rem;
  font-weight: 600;
`

const EventTime = styled.p`
  color: #fff;
  font-weight: 400;
  margin-bottom: 1rem;
`

const CTAButton = styled.a`
  color: white;
  display: flex;
  border: 1px solid white;
  padding: 1rem 2rem;
  font-size: 0.9em;
  font-weight: 400;
  width: fit-content;
  line-height: 1.5;
  cursor: pointer;
  margin: 1rem 1rem 1rem 0rem;
  &:hover {
    text-decoration: underline;
    color: #eee;
  }
`

const ReadMoreButton = styled.button`
  color: white;
  display: flex;
  background: transparent;
  border: 1px solid white;
  padding: 1rem 2rem;
  font-size: 0.9em;
  line-height: 1.5;
  width: fit-content;
  font-weight: 400;
  cursor: pointer;
  margin: 1rem 1rem 1rem 0rem;
  &:hover {
    color: #eee;
  }
`

const CtaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
`

const DiscoverEvents = ({ id, bizName }) => {
  const [regularEventData, setRegularEventData] = useState(null)
  const [recurringEventData, setRecurringEventData] = useState(null)
  const [allEvents, setAllEvents] = useState([])
  const [activeEvent, setActiveEvent] = useState({})
  const [lightBox, setLightbox] = useState({
    isOpen: false,
    mainSrc: "",
  })
  const [openEvent, setOpenEvent] = useState(null)

  const closeModal = () => setOpenEvent(null)

  const regularEventsURL = `https://data.prod.gonation.com/profile/events?profile_id=${id}`
  const recurringEventsURL = `https://data.prod.gonation.com/profile/recurringevents?profile_id=${id}`

  useEffect(() => {
    axios({
      url: regularEventsURL,
      adapter: jsonpAdapter,
    }).then(res => {
      setRegularEventData(res)
    })

    axios({
      url: recurringEventsURL,
      adapter: jsonpAdapter,
    }).then(res => {
      setRecurringEventData(res)
    })
  }, [])

  useEffect(() => {
    if (regularEventData && recurringEventData) {
      const regEvents = regularEventData.data.events
      const recEvents = recurringEventData.data.events

      setAllEvents(
        normalizeEvents(regEvents.concat(recEvents)).filter(
          (thing, index, self) =>
            index ===
            self.findIndex(
              t => t.place === thing.place && t.name === thing.name
            )
        )
      )
      // console.log("DiscoverEvents -> normalizeEvents(regEvents.concat(recEvents))", normalizeEvents(regEvents.concat(recEvents)))
      setActiveEvent(normalizeEvents(regEvents.concat(recEvents))[0])
    }
  }, [regularEventData, recurringEventData])

  const normalizeEvents = events => {
    return events.map(event => {
      if (event.eventDays) {
        return {
          isRecurring: true,
          createdAt: event.created,
          days: event.eventDays,
          description: event.description,
          tags: event.eventTags,
          name: event.name,
          starts: event.starts,
          image: event.imageurl,
          ctas: event.ctas,
          profile_id: event.profile_id,
          _id: event._id,
        }
      } else
        return {
          isRecurring: false,
          createdAt: event.createdAt,
          description: event.description,
          tags: event.eventTags,
          name: event.name,
          starts: event.starts,
          image: event.imageurl,
          ctas: event.ctas,
          profile_id: event.profile_id,
          _id: event._id,
        }
    })
  }

  const getEventTime = event => {
    if (event.isRecurring) {
      const eventDays = Object.keys(event.days)
      return `Every ${eventDays.map(day => capitalize(day))}`
    }
    return moment(event.starts).format("MMMM Do YYYY, h:mm a")
  }

  const addHTTP = url => {
    var prefix = "https://"
    if (url.substr(0, prefix.length) !== prefix) {
      return prefix + url
    }
    return url
  }

  const renderCTAs = ctas => {
    const buttonTitles = Object.keys(ctas).filter(
      btn => btn !== "cta1" && btn !== "cta2"
    )
    return buttonTitles.map(button => (
      <CTAButton
        target="_blank"
        rel="noopener noreferrer"
        href={addHTTP(ctas[button])}
      >
        {button}
      </CTAButton>
    ))
  }

  const renderEvents = () => {
    return allEvents.map((event, idx) => (
      <DiscoverEventsColumns>
        <EventContent flip={idx % 2}>
          <EventName>{event.name}</EventName>
          <LocationText>{bizName}</LocationText>
          <EventTime>{getEventTime(event)}</EventTime>
          <CtaContainer>
            <ReadMoreButton onClick={() => setOpenEvent(event)}>
              Learn More
            </ReadMoreButton>
            {renderCTAs(event.ctas)}
          </CtaContainer>
          <EventPills tags={event.tags} />
        </EventContent>
        <EventImage>
          <InteractionOverlay>
            <ImEnlarge color="#fff" size="16px" />
          </InteractionOverlay>
          <img
            onClick={() =>
              setLightbox({
                isOpen: true,
                mainSrc: event.image,
              })
            }
            src={cloudinaryOptimize(event.image, 1300)}
            alt={event.name}
          />
        </EventImage>
      </DiscoverEventsColumns>
    ))
  }

  return (
    <DiscoverEventsContainer className="evts">
      {lightBox.isOpen && (
        <Lightbox
          mainSrc={lightBox.mainSrc}
          onCloseRequest={() => setLightbox({ isOpen: false })}
        ></Lightbox>
      )}
      {allEvents && renderEvents()}
      {openEvent ? (
        <EventsModalBasic
          event={openEvent}
          modalShowing={openEvent}
          closeModal={closeModal}
        />
      ) : (
        ""
      )}
    </DiscoverEventsContainer>
  )
}

export default DiscoverEvents

const InteractionOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`
